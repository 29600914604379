.react-calendar {
  background-color: #f2f2f2;
  border: none;
}

.react-calendar__navigation {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  background-color: #ebebeb;
}

.react-calendar__tile--now {
  background-color: #2b569f;
  color: white;
}

.react-calendar__tile--now:hover {
  background-color: #2b569f !important;
}

.react-calendar__month-view__weekdays__weekday aabr {
  text-decoration: none;
}

abbr {
  text-decoration: none;
}

.Dropdown-control {
  background-color: #fafafa;
  border: none;
  font-family: 'Lato', sans-serif;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fafafa;
  border: none;
}

.Select-menu-outer * {
  max-height: 95px;
}

.flexbox-fix {
  display: none !important;
}
